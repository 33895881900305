import React, { useState, useCallback } from 'react';

import PageTemplate, {
  pageCommonPropTypes,
  renderCmsComponents,
  renderComponent,
} from './templates/PageTemplate';
import { Paragraph, FormWrapper, FormSectionRebrand } from '@paypalcorp/brc-components';

const processComponentProps = ({ componentProps, componentType }) => {
  if (componentType !== 'ArticleRatingBanner') {
    return componentProps;
  }

  return {
    ...componentProps,
    // Having this placeholder callback as a temporary solution while the
    // authoring team finishes removing all instances of ArticleRatingBanner
    handleSubmitRating: async () => {}
  };
};

const renderGatedArticleTemplate = ({
  components,
  pageInfo,
  introParagraph,
}) => {
  const [showArticleComponents, setShowArticleComponents] = useState(false);

  let articleHeader;
  let formWrapper, formSectionRebrand;
  const remainingComponents = [];

  components.forEach((componentData) => {
    const { componentType } = componentData;
    //once the FormWrapper component is deprecated, please remove the conditional statement
    if (componentType === 'FormWrapper') {
      // eslint-disable-next-line no-unused-vars
      const { componentType, ...formWrapperProps } = componentData;
      formWrapper = formWrapperProps;
    } else if (componentType === 'FormSectionRebrand') {
      // eslint-disable-next-line no-unused-vars
      const { componentType, ...formSectionRebrandProps } = componentData;
      formSectionRebrand = formSectionRebrandProps;
    } else if (componentType === 'ArticleHeader') {
      articleHeader = componentData;
    } else {
      remainingComponents.push(componentData);
    }
  });

  const changeFormVisibility = useCallback(
    (isFormHidden) => setShowArticleComponents(isFormHidden),
    [],
  );

  const onChangeFormVisibility = useCallback(
    (visibility) => setShowArticleComponents(visibility === 'hidden'),
    [],
  );

  return (
    <>
      {articleHeader &&
        renderComponent({ componentData: articleHeader, pageInfo })}
      {introParagraph && <Paragraph {...introParagraph} />}
      {showArticleComponents &&
        renderCmsComponents(
          remainingComponents,
          pageInfo,
          processComponentProps,
        )}
      {/* once the FormWrapper component is deprecated, please remove the conditional statement*/}
      {formWrapper ? (
        <FormWrapper
          {...formWrapper}
          onChangeFormVisibility={changeFormVisibility}
          isGatedForm
        />
      ) : (
        formSectionRebrand && (
          <FormSectionRebrand
            {...formSectionRebrand}
            onChangeFormVisibility={onChangeFormVisibility}
            isGatedForm
          />
        )
      )}
    </>
  );
};
const ArticlePage = ({ content = {}, fpti = {}, pageInfo = {} }) => {
  const {
    components = [],
    subNav,
    globalDisclosure,
    articleStickyButton,
    introParagraph = null,
  } = content.pageData || {};
  const { isGatedArticle = false } = pageInfo;
  return (
    <PageTemplate
      fpti={fpti}
      subNav={subNav}
      pageInfo={pageInfo}
      globalDisclosure={globalDisclosure}
      articleStickyButton={articleStickyButton}
    >
      {isGatedArticle
        ? renderGatedArticleTemplate({
          components,
          pageInfo,
          introParagraph,
        })
        : renderCmsComponents(components, pageInfo, processComponentProps)}
    </PageTemplate>
  );
};

ArticlePage.propTypes = pageCommonPropTypes;

export default ArticlePage;
