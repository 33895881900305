import React from 'react';
import ReactDOM from 'react-dom';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const hydratePageComponent = (PageComponent) => {
  const emotionCache = createCache({ key: 'brc' });

  ReactDOM.hydrateRoot(
    document.querySelector('#document-body'),
    <CacheProvider value={emotionCache}>
      <PageComponent {...window.modelData} />
    </CacheProvider>
  );
};

export { hydratePageComponent };
